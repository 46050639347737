//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

import Breadcrumb from '../components/Breadcrumb.vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import NewsletterSubscription from '../components/Partials/Newsletter/NewsletterSubscription.vue';
import Product from '../components/Product/Product.vue';
import PageElement from '../components/PageElement';
import PageRelated from '../components/PageRelated';
import { getMetaObject } from '../services/meta.util';
import FooterComponent from '../components/Footer/FooterComponent.vue';

export default {
    name: 'Page',
    mixins: [require('../mixins/product_range.js').default],
    components: {
        Breadcrumb,
        HeaderComponent,
        NewsletterSubscription,
        PageElement,
        PageRelated,
        Product,
        FooterComponent,
    },
    computed: {
        ...mapGetters(['getStoredRootline', 'getPageSlugs']),
        overlayHeadline() {
            if (this.isShowerNavigatorPage && !this.leaveShowerNavigatorConfirm) return this.$t('product_range.dialog.shower_navigator_headline');
            return this.isConfiguratorPage && !this.leaveConfiguratorConfirm ? this.$t('product_range.dialog.configurator_headline') : this.$t('product_range.dialog.headline');
        },
    },
    watch: {
        '$store.state.showProductRangeOverlay'(val) {
            this.showProductRangeOverlay = val;
            if (val === false) {
                this.productRange = this.getSelectedProductRange().toLowerCase();
            }
        },
    },
    data() {
        return {
            currentFocusIndex: this.getProductRanges().findIndex((item) => item.active === true) ?? 0,
            productRange: null,
            slug: null,
            page: null,
            showProductRangeOverlay: false,
            isTouchScreen: false,
            product: null,
            merchant: null,
            relatedDataObject: null,
            hasBreadcrumb: false,
            headerLight: false,
            isHeaderSticky: true,
            removeBreadcrumb: false,
            landingPageUrl: null,
            isConfiguratorPage: false,
            isShowerNavigatorPage: false,
            leaveConfiguratorConfirm: false,
            leaveShowerNavigatorConfirm: false,
        };
    },
    head() {
        let title = this.product?.name || this.page.meta_title || this.page.title;
        if (this.merchant) {
            title = `Bette ${title}: ${this.merchant.name1} ${this.merchant.name2}`;
        }

        const localeSlug = this.$i18n.locale === 'de' ? '' : `${this.$i18n.locale}/`;

        let slug = this.slug;

        let configuratorProductPage = false;

        if (slug === 'configurator') {
            configuratorProductPage = true;
            slug = 'configurator/products';
        }

        const linkMetaTags = [
            {
                rel: 'canonical',
                href: `https://www.my-bette.com/${localeSlug}${slug === '/' ? '' : slug}`,
            },
        ];

        Object.keys(this.getPageSlugs).forEach((key) => {
            const slug = this.getPageSlugs[key];
            if (key === 'en') {
                linkMetaTags.push({
                    rel: 'alternate',
                    hrefLang: key,
                    href: `https://www.my-bette.com${slug === '/' ? '' : slug}`,
                });
            }
            linkMetaTags.push({
                rel: 'alternate',
                hrefLang: key === 'en' ? 'x-default' : key,
                href: `https://www.my-bette.com${slug === '/' ? '' : slug}`,
            });
        });

        if (this.product && this.product.category.name) title = title + ` - ${this.product.category.name} - Bette`;

        return {
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            title: title,
            link: linkMetaTags,
            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: (this.page && this.page.robots_index && typeof this.page.robots_index === 'string' && this.page.robots_index.toLowerCase() === 'nein') || configuratorProductPage ? 'noindex,follow' : 'index,follow',
                },
                ...getMetaObject(this.page),
            ],
        };
    },
    async asyncData({ app, route, store, error, req, redirect }) {
        try {
            let relatedDataObject = null;
            let content = [];

            const getRootLine = (menuItem) => {
                let rootLine = [menuItem];
                const parentPage = (item) => {
                    if (item.parent) {
                        const parent = item.parent;
                        const parentObj = app.$findMenuItemById(parent.id, store.state.menu[app.i18n.locale]);
                        if (parentObj) {
                            parentObj.active = true;
                            rootLine.push(parentObj);
                            parentPage(parentObj);
                        }
                    }
                };
                parentPage(menuItem);
                return rootLine.reverse();
            };

            let removeBreadcrumb = null;
            let landingPageUrl = null;

            let page = null;
            let product = null;
            let merchant = null;
            let pageTranslations = {};

            const redirects = await app.api.website.getRedirects();
            const pageLocales = app.i18n.locales;
            const slug = typeof route.params.slug === 'undefined' ? '' : route.params.slug;

            if (req.headers['host']?.includes('betteair.my-bette.com')) {
                // BETTE-1960
                return redirect(301, 'https://www.my-bette.com/produkte/weitere-produktthemen/betteair');
            }

            const browserLang = req.headers['accept-language']
                ?.split(',')
                .map((langQPair) => {
                    const [lang, qValue] = langQPair.split(';');
                    const q = qValue ? parseFloat(qValue.split('=')[1]) : 1;
                    return { lang, q };
                })
                .sort((a, b) => b.q - a.q)[0]
                .lang.split('-')[0]
                .toUpperCase();

            store.commit('browserLanguage', browserLang ?? app.i18n.locale.toUpperCase());

            // Redirect /de to /
            if (slug.replace('/', '') === 'de') {
                return redirect(301, '/');
            }

            let menuItem = app.$findMenuItemBySlug(slug, store.state.menu[app.i18n.locale]);

            if (menuItem && menuItem.visible === false && !route.query.preview) {
                error({ statusCode: 404, message: 'Page not found.' });
                return;
            }

            // Redirect old product url to new
            if (route.query.p && store.state.siteProperties.product_page) {
                const newSlug = (await app.api.website.getNewProductSlug(route.query.p, slug)).data.slug;
                if (newSlug) {
                    const redirectTo = '/' + (app.i18n.locale === app.i18n.defaultLocale ? '' : app.i18n.locale + '/') + store.state.siteProperties.product_page.slug + '/' + newSlug;
                    return redirect(301, redirectTo);
                }
            }

            // Redirect old url to new
            let newRedirectUrl = redirects?.[app.i18n.locale][`/${slug}`];
            if (newRedirectUrl && newRedirectUrl !== route.path) {
                return redirect(301, newRedirectUrl);
            }

            // Check if the page is a product page and set the product
            if (store.state.siteProperties.product_page && slug.split('/')[0] === store.state.siteProperties.product_page.slug) {
                menuItem = app.$findMenuItemById(store.state.siteProperties.product_page.id, store.state.menu[app.i18n.locale]);
                const productSlug = slug.replace(store.state.siteProperties.product_page.slug.replace('/', ''), '').replace('/', '');
                product = await app.api.product.getProduct(productSlug);

                if (product === null) {
                    // Testing for product by external key
                    const productSlugRedirect = await app.api.product.getProductSlugByExternalKey(productSlug, app.i18n.locale);
                    if (productSlugRedirect) {
                        return redirect(301, `/${app.i18n.locale === app.i18n.defaultLocale ? '' : app.i18n.locale + '/'}${store.state.siteProperties.product_page.slug}/${productSlugRedirect}`);
                    }

                    console.error('Product not found! Slug:', productSlug);
                    error({ statusCode: 404, message: 'product not found.' });
                    return;
                }
                if (product === false) {
                    console.error('An error has occurred, please try again later. Slug:', productSlug);
                    error({
                        statusCode: 500,
                        message: 'An error has occurred, please try again later.',
                    });
                    return;
                } else {
                    product.slugs = await app.api.product.getProductLocalizedSlugs(product.id);
                    store.commit('product', product);

                    if (product.hasLandingPage) {
                        const landingPage = await app.api.product.getLandingPage(app.i18n.locale, product.id);
                        if (landingPage) {
                            landingPageUrl = landingPage.landingPageUrl;
                            removeBreadcrumb = true;
                        }
                    }

                    // if there is a variant key in the query, then set this variant as current variant
                    // falback to first variant if the query variant can not be found

                    let queryVariant = null;

                    if (route.query.variant) {
                        queryVariant = product.variants.filter((v) => v.externalKey === route.query.variant)[0];
                    }

                    if (product?.variants && product.variants.length) {
                        product.variants = product.variants.sort((a, b) => {
                            return a.prio < b.prio ? -1 : 1;
                        });
                        store.commit('productVariant', {
                            productVariant: queryVariant || product?.variants[0],
                            updateQuery: false,
                        });
                    }
                }
            }

            // Check if the page is a merchant page and set the merchant
            if (store.state.siteProperties.merchant_page && slug.split('/')[0] === store.state.siteProperties.merchant_page.slug) {
                if (store.state.siteProperties.merchant_search_page && store.state.siteProperties.merchant_page) {
                    // set menuItem to merchant search page
                    menuItem = app.$findMenuItemById(store.state.siteProperties.merchant_search_page.id, store.state.menu[app.i18n.locale]);

                    const merchantSlug = slug.replace(store.state.siteProperties.merchant_page.slug.replace('/', ''), '').replace('/', '');

                    merchant = await app.api.Merchant.getMerchant(merchantSlug, app.i18n.locale);

                    if (!merchant) {
                        console.error('Merchant not found! Slug:', merchantSlug);
                        error({
                            statusCode: 404,
                            message: 'merchant not found.',
                        });
                        return;
                    } else {
                        if (store.state.siteProperties.merchant_page.slugs) {
                            merchant.slugs = {};
                            app.i18n.locales.forEach((item) => {
                                merchant.slugs[item.code] = {
                                    slug: merchantSlug,
                                };
                            });
                        }
                    }
                } else {
                    console.error('merchant_search_page not set.');
                }
            }

            // Set all page translations for the current page
            for (const pageLocale of pageLocales) {
                if (page === null && menuItem && pageLocale.code === app.i18n.locale) {
                    page = await app.api.website.getPage(menuItem.id, pageLocale.code);
                    pageTranslations[pageLocale.code] = page;
                } else if (menuItem) {
                    const translatedMenuItem = app.$findMenuItemById(menuItem.id, store.state.menu[pageLocale.code]);
                    if (translatedMenuItem) {
                        pageTranslations[pageLocale.code] = translatedMenuItem;
                    }
                }
            }

            if (page) {
                let rootLine = getRootLine(menuItem);
                const filterEmptyChildren = (item) => {
                    if (item.children) {
                        item.children = item.children.filter((i) => JSON.stringify(i) !== JSON.stringify({}));
                    }
                };

                if (product) {
                    const productMenuPage = product.properties.filter((item) => item.name === 'menu_page')[0]?.object || null;
                    if (productMenuPage) {
                        menuItem = app.$findMenuItemById(productMenuPage.id, store.state.menu[app.i18n.locale]);
                        rootLine = getRootLine(menuItem);
                        rootLine.push(store.state.product);
                    }
                }

                // Check if the page is enriched by a related data-object
                if (page.objectRelation && page.objectRelation.id) {
                    const classname = page.objectRelation.classname;
                    const id = page.objectRelation.id;

                    relatedDataObject = await app.api[classname].show(id, app.i18n.locale);

                    if (relatedDataObject) {
                        relatedDataObject.classname = classname;
                    }
                }

                store.commit('rootLine', rootLine);
                store.commit('pageTranslations', pageTranslations);

                let ps = null;
                if (page.children && page.children.length) {
                    filterEmptyChildren(page);
                    content = await app.api.website.getElements(page.children[0], app.i18n.locale);
                    page.content = content;
                    ps = await app.api.website.getLocalizedPageSlugs(page.id);
                    store.commit('page', page);
                    store.commit('pageSlugs', {
                        pageItems: ps,
                        defaultLocale: app.i18n.defaultLocale,
                    });

                    if (merchant) {
                        rootLine.push(merchant);
                        store.commit('pageSlugs', {
                            pageItems: merchant.slugs,
                            slugPrefix: store.state.siteProperties.merchant_page.slugs,
                            defaultLocale: app.i18n.defaultLocale,
                        });
                    }
                } else if (product) {
                    store.commit('pageSlugs', {
                        pageItems: product.slugs,
                        slugPrefix: store.state.siteProperties.product_page.slugs,
                        defaultLocale: app.i18n.defaultLocale,
                    });
                } else {
                    ps = await app.api.website.getLocalizedPageSlugs(page.id);
                    store.commit('pageSlugs', {
                        pageItems: ps,
                        defaultLocale: app.i18n.defaultLocale,
                    });
                }

                if (req.headers['referer'] && req.headers['referer'].includes('https://go.my-bette.com/l/') && ps[browserLang.toLowerCase()]) {
                    const locale = browserLang.toLowerCase();
                    const slug = ps[browserLang.toLowerCase()].slug;
                    const redirectUrl = `${locale ? '/' + locale : ''}${slug}`;
                    return redirect(307, redirectUrl);
                }

                const disableStickyHeaderProp = page?.properties?.find((p) => p.name === 'disable_sticky_header');
                const isTouchScreen = page?.properties?.find((p) => p.name === 'touch_screen')?.checked === true;

                return {
                    isTouchScreen,
                    slug,
                    page,
                    content,
                    pageTranslations,
                    product,
                    landingPageUrl,
                    removeBreadcrumb,
                    merchant,
                    relatedDataObject,
                    isHeaderSticky: !disableStickyHeaderProp?.checked,
                };
            }

            console.error('Page not found: ', app.context.route.fullPath);
            return error({ statusCode: 404, message: 'Page not found.' });
        } catch (e) {
            console.error(e);
            console.error(app.context.route.fullPath);
            return error({
                statusCode: 500,
                message: 'An error has occurred, please try again later.',
            });
        }
    },
    created() {
        if (this.content && this.page) {
            this.headerLight = this.content.filter((item) => item.__typename === 'object_StageHomepage').length > 0;
            // Show breadcrumb
            // TODO: nicht auf der Suchergebnisseite
            const disableBreadcrumbProp = this.page.properties.find((p) => p.name === 'disable_breadcrumb');
            this.hasBreadcrumb = this.getStoredRootline.length > 1 && !disableBreadcrumbProp?.checked;
            this.isConfiguratorPage = this.page.properties.find((p) => p.name === 'configurator');
            this.isShowerNavigatorPage = this.page.properties.find((p) => p.name === 'shower_navigator');
        }
    },
    mounted() {
        if (process.client) {
            var browserUpdate = require('browser-update');
            browserUpdate({
                required: { e: -4, f: -4, o: -4, s: -4, c: -4 },
                insecure: true,
                unsupported: true,
            });
        }
        // Add class "homepage" if current rootline level is equal 1
        if (this.getStoredRootline.length === 1) {
            document.body.classList.add('homepage');
        }

        /**
         *  Handle focus outline, only on keydown event
         */
        document.body.addEventListener('mousedown', () => {
            document.body.classList.add('using-mouse');
        });
        document.body.addEventListener('keydown', () => {
            document.body.classList.remove('using-mouse');
        });
        this.productRange = this.getSelectedProductRange()?.toLowerCase();

        // Trap focus within the modal when it's open
        this.$watch('showProductRangeOverlay', (isOpen) => {
            if (isOpen) {
                this.$nextTick(() => {
                    const focusableElements = this.$el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
                    const firstElement = focusableElements[0];
                    const lastElement = focusableElements[focusableElements.length - 1];

                    this.$el.addEventListener('keydown', (e) => {
                        if (e.key === 'Tab') {
                            if (e.shiftKey && document.activeElement === firstElement) {
                                e.preventDefault();
                                lastElement.focus();
                            } else if (!e.shiftKey && document.activeElement === lastElement) {
                                e.preventDefault();
                                firstElement.focus();
                            }
                        }
                    });

                    firstElement.focus();
                });
            }
        });
    },
    methods: {
        getAriaLabel(item, index) {
            const country = this.$t(`product_range.country.${item.code.toLowerCase()}`);
            const position = index + 1;
            const total = this.getProductRanges().length;
            return this.$t('product_range.aria_label', { country, position, total });
        },
        setProductRange() {
            this.$store.commit('showProductRangeOverlay', false);
            const configuratorProductPage = this.$getLocalizedSlug(this.$store.state.siteProperties.configurator_product_page.id) ?? null;
            const showerNavigatorPage = this.$getLocalizedSlug(this.$store.state.siteProperties.shower_navigator_page.id) ?? null;
            this.setSelectedProductRange(this.productRange, configuratorProductPage, this.isConfiguratorPage, showerNavigatorPage, this.isShowerNavigatorPage);
        },
        closeModal() {
            this.$store.commit('showProductRangeOverlay', false);
            this.leaveConfiguratorConfirm = false;
            this.leaveShowerNavigatorConfirm = false;
        },
        navigateRadios(direction) {
            const radios = this.getProductRanges();
            this.currentFocusIndex = (this.currentFocusIndex + direction + radios.length) % radios.length;
            this.productRange = radios[this.currentFocusIndex].code.toLowerCase();

            this.$nextTick(() => {
                const radioInput = document.getElementById(this.productRange);
                const radioAnchor = document.querySelector(`#${this.productRange}-anchor`);

                if (radioInput && radioAnchor) {
                    radioAnchor.focus();
                    radioInput.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
            });
        },
    },
};
